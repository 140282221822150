body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  z-index: 1;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #3492D3;
  top: 0;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #195BA6;
}

@media (max-width: 991px) {
  .navbar {
    z-index: 1;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    font-size: 15px;
  }

  li {
    float: left;
    padding: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background-color: #3492D3;
    top: 0;
  }
}
