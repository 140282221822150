body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.Languages{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #B1C3CC;
  height: 400px;
  width: 100%;
}

.Languages-images{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 100px;
  padding: 30px;
}
