body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.header{
  padding: 0;
  margin: 0;
  height: 0;
  width: 0;
}

@media (max-width: 740px) {
  html,
  body,
  header,
  .view {
    height: 100vh;
  }
}

.top-nav-collapse {
  background-color: #3492D3 ;
}

.navbar:not(.top-nav-collapse) {
  background: #3492D3 ;
}

@media (max-width: 991px) {
  .navbar:not(.top-nav-collapse) {
    background: #3492D3 ;
  }

  .Languages-images{
    height: 100px;
    padding: 0;
    }
}

.hero-image {
  display: flex;
  background-image: url("/images/desktop.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 900px;
  width: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
}

.hero-text-master{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3rem;
  text-shadow: 2px 2px 4px #000000;
}

.hero-text {
  text-shadow: 2px 2px 4px #000000;
}

.hero-text-2 {
  font-family: Courier, monospace;
	display: inline-block;
}

.typewriter-hero-text-2 {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
 	animation: typing 5s steps(30, end), blink .75s step-end infinite;
  white-space: nowrap;
  font-size: 2rem;
  font-weight: 700;
  border-right: 4px solid;
  box-sizing: border-box;
  width: 100%;
}

.hero-text-3 {
  font-family: Courier, monospace;
	display: inline-block;
}

.typewriter-hero-text-3 {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
 	animation: typing 5s steps(30, end), blink .75s step-end infinite;
  white-space: nowrap;
  font-size: 2rem;
  font-weight: 700;
  border-right: 4px solid;
  box-sizing: border-box;
  width: 100%;
  height: 70%
}

@keyframes typing {
    from {
        width: 0%
    }
    to {
        width: 100%
    }
}

@keyframes blink {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: #3492D3;
    }
}

.about {
  text-align: center;
  font-size: 2em;
}

.about-master{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 80px;
}

.about-title{
  text-align: center;
}

.aboutPic {
  float: left;
  padding: 1em;
}

.about-text{
  height: 50%;
  justify-content:center;
  padding: 8px;
  margin: 20px;
  float: left;
}

.button-master{
  display: flex;
  justify-content: space-around;
}

.resume-download{
  background-color: #4ABCFC;
  padding: 1rem;
  border-radius: 8px;
}

.contact-button{
  background-color: #4ABCFC;
  padding: 1rem;
  border-radius: 8px;
}

a{
  color: white;
}

.Languages-text{
  text-align: center;
  width: 100%;
}

.Languages{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #B1C3CC;
  height: 400px;
  width: 100%;
}

.Languages-images{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;
}

.bgimage-2 {
  background-image: url("/images/IMG_0120.jpeg");
  background-size: cover;
  height: 500px;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.portfolio{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 100px;
}

.masterResume{
  display: flex;
}

.resume-text{
  text-align: center;
}

.projects{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
}

.row-2{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
}

.row-3{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
}

.Art-Archive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25%;
  margin-bottom: 60px;
  background-color: #4ABCFC;
  padding: 1rem;
  border-radius: 8px;
  width: 50%;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 770px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }

  .about-master{
    padding: 0;
  }

  .form{
    font-size: 0;
  }

  .typewriter-hero-text-2 {
    display: inline-block;
    overflow: hidden;
    letter-spacing: 2px;
   	animation: typing 5s steps(30, end), blink .75s step-end infinite;
    white-space: nowrap;
    font-size: 25px;
    font-weight: 700;
    border-right: 4px solid;
    box-sizing: border-box;
    width: 100%;
  }

  .typewriter-hero-text-3 {
    display: inline-block;
    overflow: hidden;
    letter-spacing: 2px;
   	animation: typing 5s steps(30, end), blink .75s step-end infinite;
    white-space: nowrap;
    font-size: 25px;
    font-weight: 700;
    border-right: 4px solid;
    box-sizing: border-box;
    width: 100%;
  }

  .Languages-images{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    width: 70px;
    padding: 2px;
  }

}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }

  .typewriter-hero-text-2 {
    display: inline-block;
    overflow: hidden;
    letter-spacing: 2px;
   	animation: typing 5s steps(30, end), blink .75s step-end infinite;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    border-right: 4px solid;
    box-sizing: border-box;
    width: 100%;
  }

  .typewriter-hero-text-3 {
    display: inline-block;
    overflow: hidden;
    letter-spacing: 2px;
   	animation: typing 5s steps(30, end), blink .75s step-end infinite;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    border-right: 4px solid;
    box-sizing: border-box;
    width: 100%;
  }

  .contact-master{
    display: flex;
    flex-direction: column;
  }

  .contact-icons{
    padding: 4px;
  }

  .form{
    display: flex;
    flex-direction: column;
  }
}

.contact-master{
  padding: 5rem;
  margin: 5rem;
  display: flex;
  justify-content: space-around;
}

.contact-text{
  display: flex;
  background-image: url("/images/contact-stock.jpg");
  background-size: cover;
  background-attachment:fixed;
  height: 300px;
  width: 100%;
  text-align: center;
  justify-content: center;
  top: 70%;
  left: 50%;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 100px;
}

.form{
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 2rem;
  width: 100%;
}

.contact-icons{
  display: flex;
  align-items: center;
  justify-content:space-around;
  text-align: center;
  width: 100%;
}

.social-media{
  justify-content: flex-end;
}

.top-button{
  text-align: center;
  background-color: #4ABCFC;
  padding: 10px;
  border-radius: 8px;
}
