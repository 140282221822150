body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.warning {
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 20px;
  background-color: #4ABCFC;
  padding-bottom: 100px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1070px) {
  .warning {
    text-align: center;
    position: absolute;
    top: 70%;
    color: black;
    font-size: 20px;
    background-color: #4ABCFC;
    width: 300px;
    padding-top: 700px;
    margin-bottom: 800px;
    padding-bottom: 500px;
  }
}
