body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.bgimage-2 {
  background-image: url("/images/IMG_0120.jpeg");
  background-size: cover;
  height: 500px;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.portfolio{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 100px;
}

@media only screen and (max-width: 1070px) {
  .portfolio{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px #000000;
    font-size: 50px;
  }
}


.projects{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
}

.row-2{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
}

.row-3{
  display: flex;
  justify-content: center;
  margin: 5rem;
  align-items: center;
  padding-bottom: 300px;
}
